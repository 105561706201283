function hamburger() {
  document.getElementById('top').classList.toggle('line_1');
  document.getElementById('middle').classList.toggle('line_2');
  document.getElementById('bottom').classList.toggle('line_3');
  document.getElementById('nav').classList.toggle('in');
}
document.addEventListener('turbolinks:load', function() {
  document.getElementById('hamburger').addEventListener('click' , function () {
    hamburger();
  } );
})
